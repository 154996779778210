import React from "react"
import { Helmet } from "react-helmet"

type SeoProps = {
  content: any
  language?: string
}

const Seo: React.FC<SeoProps> = ({ content, language }) => {
  const seo = {
    title: content?.title,
    overrideTitle: content.overrideTitle,
    description: content?.description,
    image: content.image,
    url: content.url,
    canonical: content?.canonical,
    article: content.article,
  }

  return (
    <React.Fragment>
      <Helmet titleTemplate={`%s | ${seo.title}`} defaultTitle={seo.title} />
      <Helmet link={[
        { rel: 'canonical', href: seo.canonical }
      ]}>
        {seo.overrideTitle && <title>{seo.overrideTitle}</title>}
        <html lang={language || "es"} />
        <meta httpEquiv="content-language" content={language || "es"} />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {seo.url && <meta property="og:url" content={seo.url} />}

        <meta property="og:type" content={seo.article ? 'article' : 'website'} />

        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {seo.image && <meta property="og:image" content={seo.image} />}
        <meta name="twitter:card" content="summary_large_image" />
        {seo.title && <meta name="twitter:title" content={seo.title} />}
        {seo.description && (
          <meta name="twitter:description" content={seo.description} />
        )}
        {seo.image && <meta name="twitter:image" content={seo.image} />}
      </Helmet>
    </React.Fragment>
  )
}

export default Seo
